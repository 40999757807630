import React, { useContext } from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

/* Paginas */
import Site from './site/site.jsx';
import Login from './app/Login/login';
import NovaConta from './app/NovaConta/novaconta';
import ResetSenha from './app/ResetSenha/resetsenha';
import Home from './app/Home/home';
import Instalacoes from './app/instalacoes/instalacoes';
import Representantes from './app/representantes/representantes';
import Licencas from './app/licencas/licencas';
import teste2 from './app/teste2/teste';
import NovoCliente from './app/NovoCliente/novocliente';
import EditarCliente from './app/EditarCliente/editarcliente';
import Monitorar from './app/servidor/monitorar';
import {Analitico} from './app/analitico/analitico';
import { AuthContext } from './app/Context/auth.jsx';

function App(){
  const {logado} = useContext(AuthContext);

  function SecureRoute({...params}){
    if (!logado){
      return <Redirect to="/app" />
    } else {
    return <Route {...params} />
    }
  }

  return <BrowserRouter>
    <Switch>
      <Route exact path='/' component={Site} />    
      <Route exact path='/app' component={Login} />    
      <Route exact path='/app/novaconta' component={NovaConta} />    
      <Route exact path='/app/resetsenha' component={ResetSenha} />    
      <SecureRoute exact path='/app/instalacoes' component={Instalacoes} />    
      <SecureRoute exact path='/app/representantes' component={Representantes} />    
      <SecureRoute exact path='/app/licencas' component={Licencas} />    
      <SecureRoute exact path='/app/teste2' component={teste2} />    
      <SecureRoute exact path='/app/home' component={Home} />    
      <SecureRoute exact path='/app/home/:id' component={Home} />    
      <SecureRoute exact path='/app/novocliente' component={NovoCliente} />
      <SecureRoute exact path='/app/editarcliente/:id' component={EditarCliente} />
      <SecureRoute exact path='/app/monitorar' component={Monitorar} />
      <SecureRoute exact path='/app/analitico' component={Analitico} />
    </Switch>    
    </BrowserRouter>;
}
 
export default App;