import React, { useState,useContext } from 'react';
import {Link, Redirect}  from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './novocliente.css';
import SweetAlert from 'react-bootstrap-sweetalert';

import firebase from '../Config/firebase';
import {AuthContext} from '../Context/auth';


function NovoCliente(props){

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [fone, setFone] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [sucesso, setSucesso] = useState('N');
    const {alterar} = useContext(AuthContext);
    const {setAlterar} = useContext(AuthContext);
    const [showform,setShowForm] = useState(true);
      const db = firebase.firestore();

      function CloseForm()
      {
        setShowForm(false);
      }
      function CadastrarCliente(){

      if (nome.length === 0){
        setMensagem('Informe o nome');
      }
      else if (email.length === 0){
        setMensagem('Informe o e-mail');
      }
      else{
          db.collection('clientes').add({
            nome: nome,
            email: email,
            fone: fone
          }).then(() => {
            setMensagem('');
            setSucesso('S');
          }).catch((erro) =>{
            setMensagem(erro);
            setSucesso('N');
          })
        }
      }
    return showform?<SweetAlert
    style={{ width:'80%' }}
    showConfirm={false}
  ><div>
    
          <div className="offset-lg-3 col-lg-6">
    <h1>Novo Cliente</h1>
            <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Nome</label>
                <input onChange={(e) => setNome(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />              
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">E-mail</label>
                <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />              
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Fone</label>
                <input  onChange={(e) => setFone(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />              
              </div>

              <div className="text-center">
              <button onClick={CloseForm} type="button" className="btn btn-primary btn-acao">Cancelar</button>
              <button onClick={CadastrarCliente} type="button" className="btn btn-primary btn-acao">Salvar</button>
              </div>

              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Redirect to='/app/home' /> : null}

            </form>
          </div>
        </div></SweetAlert>:null
    ;  
  }

export default NovoCliente;