//Cliente.js
module.exports = class ListTable {
    //propriedades e funções da classe aqui
    constructor(table, index, where,fieldsForm, fieldSearch, fields,columns,title,key) {
    
        this.table = table;
        this.index = index;
        this.where = where;
        this.fieldsForm = fieldsForm;
        this.fields = fields;
        this.columns = columns;
        this.title = title;
        this.fieldSearch = fieldSearch;
        this.key=key;
    }
    
}