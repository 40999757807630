import Usuarios from '../Components/ListTable/listtable';
import table from '../Components/ListTable/querylist';
function listar()
{
    let Table = new table("pagamento");
    Table.fields="ID,DATA,EMPRESA";
    Table.coluns=["Código","DATA","EMPRESA"];
    Table.where="";
    Table.index="DATA";
    Table.title="Lista de Pagamentos";

return <Usuarios paramlist={Table} ></Usuarios>
}
export default listar
