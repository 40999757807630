import React, { useState, useEffect } from 'react';
//import {Link, Redirect}  from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import Aguarde from '../Components/Aguarde/aguarde';
import './monitorar.css';
import Footer from '../Components/footer';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';



function Monitorar(props){
  const [ShowAguarde, setShowAguarde] = useState(false); 
  const [Servers, setServers] = useState('');
  const [ConfirmaAcao, setConfirmaAcao] = useState(false);
  const [TitleAcao, setTitleAcao] = useState('');
  //"action":0 null 1 ligar 2 desligar 3 reiniciar
  const [ServerRegion, setServerRegion] = useState({"instance":"teste","region":{"name":"name1","value":"brasil"},"action":0});
  
  
  useEffect(function(){
      getServer();
   }, []);

   
   
function getServer()
{
  setShowAguarde(true);
  setConfirmaAcao(false);
  let payload = {"data":{"action":0    
  }};
  axios.post('https://api007.foxspy.com.br/users/server/monitor', payload).then(res => {
   console.log(res.data.data[0]);
   var lista=[] ;
   var aList = [];
   aList = res.data.data;
   aList.forEach(obj=>{
    let ServerUp = "fa fa-star ".concat(obj.state.Code===16?"run":"notrun"); 
    let Buttunon = ( [0,16,64].includes(obj.state.Code)?true:false); 
    let Buttunoff = ( [16].includes(obj.state.Code)?false:true); 
    lista.push(<div class="card w-100">
      <div class="card-body">
   <h5 class="card-title">{obj.instance}<i class={ServerUp}>{obj.state.Name}</i></h5>
   <p class="card-text">Localizado na região de {obj.region.name}</p>
   <button type="button" href="#" class="btn btn-success" disabled={Buttunon} onClick={() => {setConfirmaAcao(true);setTitleAcao("Deseja LIGAR o servidor?");setServerRegion({"instanceId":obj.instanceId,"instance":obj.instance,"region":obj.region,"action":1})}}>Ligar</button>&nbsp;&nbsp;
   <button type="button" href="#" class="btn btn-danger" disabled={Buttunoff} onClick={() => {setConfirmaAcao(true);setTitleAcao("Deseja DESLIGAR servidor?");setServerRegion({"instanceId":obj.instanceId,"instance":obj.instance,"region":obj.region,"action":2})}}>Desligar</button>&nbsp;&nbsp;
   <button type="button" class="btn btn-warning" disabled={Buttunoff} onClick={() => {setConfirmaAcao(true);setTitleAcao("Deseja REINICIAR o servidor?");setServerRegion({"instanceId":obj.instanceId,"instance":obj.instance,"region":obj.region,"action":3})}}>Reiniciar</button></div></div>);
   });
   setServers(lista);
   setShowAguarde(false);

 })
 .catch(error => {   
   console.error(error)
 }) ;


}
function Acao(ServReg)
{
    setConfirmaAcao(false);
    setShowAguarde(true);
    let payload = {"data":ServReg};
    axios.post('https://api007.foxspy.com.br/users/server/monitor', payload).then(res => {
      getServer();
     });
  
}
    return <div>
      <Navbar/>
      <div className="container-fluid titulo">
<h1>Lista de Servidores Amazon</h1>

      <div className="row">
      {Servers}
      </div>
      {ShowAguarde?<Aguarde/>:null}
      {ConfirmaAcao?<SweetAlert
                        warning
                        showCancel
                        showCloseButton
                        confirmBtnText="Sim"
                        confirmBtnBsStyle="danger"
                        cancelBtnText="Não"
                        cancelBtnBsStyle="light"
                        title="Atenção"
                        onConfirm={() => Acao(ServerRegion)}
                        onCancel={() => setConfirmaAcao(false)}          
                        reverseButtons={true}
                      >
                        <b>{ServerRegion.instanceId+' : '+ServerRegion.instance}</b><br/><br/>
                        {TitleAcao}
                      </SweetAlert> : null }      
      </div>
      <Footer/>       
    </div>;  
  }

export default Monitorar;
