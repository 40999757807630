import Representantes from '../Components/ListTable/listtable';
import table from '../Components/ListTable/querylist';

function listar()
{
    let Table = new table("serialcelular");
//    Table.fields="ID,IMEI,MENSAGEM";
    Table.fields=["CHAVE","IMEI","DIAS","DATA","REGISTRADO","(SELECT REPRESENTANTE FROM revenda WHERE CODIGO=EMPRESA) REPRESENTANTE"];
    Table.columns=["CHAVE","IMEI","DIAS","DATA","REGISTRADO","REPRESENTANTE"];
    Table.where="";
    Table.index="DATA desc";
    Table.title="Licenças";
    Table.fieldSearch = "DATA";
// Form Alterar

    Table.fieldsForm=[
    {"field":"CHAVE","title":"CHAVE","type":"text","div":{"row":'300px',"col":"180px"}},
    {"field":"IMEI","title":"IMEI","type":"text","div":{"row":'250px',"col":"180px"}},
    {"field":"DIAS","title":"DIAS","type":"number","div":{"row":'150px',"col":"100px"}},
    {"field":"EMPRESA","title":"CÓDIGO REVENDA","type":"NUMBER","div":{"row":'500px',"col":"100px"}},
    {"field":"DATA","title":"DATA","type":"datetime-local","div":{"row":'300px',"col":"100%"}},
    {"field":"REGISTRADO","title":"REGISTRADO","type":"datetime-local","div":{"row":'300px',"col":"100%"}},
    ];
    Table.key={"field":"CHAVE","AUTO_INCREMENT":false};
////    

    

return <Representantes paramlist={Table} paramalter={Table} > 
</Representantes>
}
//{alterar?<EditarInstalacao Alterar={propsTable.paramalter}/>: null }
export default listar
