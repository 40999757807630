import React,  {useState} from 'react';
import {Link} from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import './paginator.css';

function Paginator(props){
    let items=[];
    let countpages=5;
    let prev = props.arrayPaginator.prev;
    let next = props.arrayPaginator.next;
    let last = props.arrayPaginator.pages;
    const [pageinit, setPageInit] = useState([1]);
    if(props.arrayPaginator.hasPrev)
    {
        items.push(<Link style={{ textDecoration: 'none' }} to='#' onClick={() =>  {props.ClickPage(1);setPageInit(1)}}><Pagination.First ></Pagination.First></Link>);
        items.push(<Link style={{ textDecoration: 'none' }} to='#' onClick={() =>  {props.ClickPage(prev);setPageInit((pageinit===1?1:parseInt(pageinit)-1))}}><Pagination.Prev  /></Link>);
    }
    else {
        items.push(<Pagination.First disabled/>);
        items.push(<Pagination.Prev disabled/>);
    }
    let initial = parseInt(pageinit);    
    let endpage = (initial+countpages>props.arrayPaginator.pages?props.arrayPaginator.pages:initial+countpages);
    for (let index = initial; index <= endpage; index++) {
           let href=""+index;
          if (index==props.arrayPaginator.page)
             items.push(<PageItem active>{index}</PageItem> )
          else
             items.push(<Link style={{ textDecoration: 'none' }} to='#' onClick={() =>  props.ClickPage(index)}><PageItem>{index}</PageItem></Link>)
          
        }
    if(props.arrayPaginator.hasNext)
    {
        items.push(<Link style={{ textDecoration: 'none' }} to='#' onClick={() =>  {props.ClickPage(next);setPageInit(parseInt(pageinit)+1)}}><Pagination.Next /></Link>);
        items.push(<Link style={{ textDecoration: 'none' }} to='#' onClick={() =>  {props.ClickPage(last);setPageInit(parseInt(last))}}><Pagination.Last /></Link>);
    }
    else
    {       
        items.push(<Pagination.Next disabled/>);
        items.push(<Pagination.Last disabled/>);
    }    
    //teste.concat(<Pagination.Next disabled/>);
    return  <div> 
    <Pagination>
    {items}
  </Pagination>
  </div>

}


export default Paginator;