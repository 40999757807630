import React, { useState,useContext} from 'react';
import {Link} from 'react-router-dom';
import './listacliente.css';
import {AuthContext} from '../../Context/auth';
import SweetAlert from 'react-bootstrap-sweetalert';
import NovoCliente from '../../NovoCliente/novocliente';



function ListaClientes(props){
    const [titleindex, setTitleIndex] = useState({"index":"1","click":"up"});
    const {alterar} = useContext(AuthContext);
    const {setAlterar} = useContext(AuthContext);

    function deleteUser(id){
        alert('Exluir usuário ' + id);
    }
  
    return <div class="table-responsive"> 
    <table className="table table-hover table-bordered">
            <thead>
            <tr className="table-secondary">
                 {  
                    props.arrayTitle.map((title,nCol)=>
                    {
                        let colserch=title==titleindex.index?"fas fa-angle-"+titleindex.click:'';
                        let sClick = (titleindex.index==title?(titleindex.click=="up"?"down":"up"):"down"); 
                        return <th scope="col" onClick={()=>
                            {
                                setTitleIndex({"index":title,"click":sClick});
                                props.setFieldSearch({"index":nCol,"title":title});
                                let indexcolunm = nCol+1+(sClick=="up"?" desc":" asc");
                                props.ListRows(1,indexcolunm);
                                //props.setIndexCol(indexcolunm);
                            }}>{title}<b className={colserch}></b></th>
                    }) }
                   
                
                <th scope="col" className="col-acao"></th>
            </tr>
            </thead>
            <tbody>

            {
                props.arrayClientes.map((cliente) => {
                    let cols=[];
                    let Col0;
                    let count = 0;
                    for (var field in cliente ) {
                        if(count>0)
                          cols.push(<td>{cliente[field]}</td>);
                        else
                          Col0 = field;
                        count++;  
                    }
                    return <tr key={cliente[Col0]}>
                        <th scope="row">{cliente[Col0]}</th>
                        {cols}
                        <td>
                            <Link to='#' onClick={() => props.ClickAlter(cliente[Col0])}><i className="fas fa-edit icone-acao"></i></Link>
                            <Link to='#' onClick={() =>  props.ClickDelete(cliente[Col0])}><i className="far fa-trash-alt icone-acao red"></i></Link>
                        </td>
                        </tr>
                })
            }
                       
            
            </tbody>
        </table>
        </div>         

}

export default ListaClientes;