import Instalacoes from '../Components/ListTable/listtable';
import table from '../Components/ListTable/querylist';

function listar()
{
    let Table = new table("celular");
//    Table.fields="ID,IMEI,MENSAGEM";
    Table.fields=["IMEI","DT_GERADO","CONECTADO","EMAIL","VENCIMENTO","FABRICANTE","MODELO","VERSAO","VERSAOAPP","REPRESENTANTE"];
    Table.columns=["IMEI","INSTALADO","CONECTADO","EMAIL","VENCIMENTO","FABRICANTE","MODELO","VERSAO","VERSAOAPP","REPRESENTANTE"];
    Table.where="";
    Table.index="DT_GERADO desc";
    Table.title="Instalações";
    Table.fieldSearch = "IMEI"
    Table.fieldsForm=[
        {"field":"REPRESENTANTE","title":"REPRESENTANTE","type":"text","div":{"row":'1000px',"col":"180px"}},
        {"field":"IMEI","title":"NUMERO DO IMEI","type":"text","div":{"row":'400px',"col":"220px"}},
        {"field":"EMAIL","title":"EMAIL","type":"email","div":{"row":'1000px',"col":"500px"}},
        {"field":"SENHA","title":"SENHA","type":"text","div":{"row":'1000px',"col":"500px"}},
        {"field":"DT_GERADO","title":"DATA CADASTRO","type":"datetime-local","div":{"row":'450px',"col":"100%"}},
        {"field":"VENCIMENTO","title":"VENCIMENTO","type":"datetime-local","div":{"row":'400px',"col":"100%"}},
        ];
    Table.key = {"field":"IMEI","AUTO_INCREMENT":false};
    

return <Instalacoes paramlist={Table} paramalter={Table} > 
</Instalacoes>
}
//{alterar?<EditarInstalacao Alterar={propsTable.paramalter}/>: null }
export default listar
