import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../Components/Navbar/navbar';
import Footer from '../Components/footer';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import './theme.css';
import Aguarde from '../Components/Aguarde/aguarde';




export const Analitico = () => {
    const [ShowAguarde, setShowAguarde] = useState(false); 

    const [instalacoes, setInstalacoes] = useState(null);
    useEffect(() => {
        setShowAguarde(true);
        axios.get('https://api007.foxspy.com.br/users/analitico').then(res => {setInstalacoes(res.data.data);
        setShowAguarde(false);});
    }, []);

    const header = (
        <div className="container-fluid titulo">
            <h5 className="m-0">Analise das Instalações</h5>
        </div>
    );    
    const diaBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.dia}
            </>
        );
    }

    const representanteBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.representante}
            </>
        );
    }
    const instalacoesBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.instalacoes}
            </>
        );
    }


    return (
        <div>
        <Navbar/>
        {header}
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">

                    <DataTable value={instalacoes} 
                        dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords}"
                        emptyMessage="No products found." >
                        <Column field="dia" header="Dia" sortable body={diaBodyTemplate}></Column>
                        <Column field="representante" header="Representante" sortable body={representanteBodyTemplate}></Column>
                        <Column field="instalacoes" header="Instalacoes" sortable body={instalacoesBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            {ShowAguarde?<Aguarde/>:null}
        </div>
        <Footer/>
        </div>
    );

}