import Usuarios from '../Components/ListTable/listtable';
import table from '../Components/ListTable/querylist';
function listar()
{
    let Table = new table("CADASTRO");
    Table.fields=["ID","NOME"];
    Table.columns=["Código","NOME"];
    Table.where="";
    Table.index="NOME";
    Table.fieldSearch="NOME";
    Table.title="Lista de Cliente";

return <Usuarios paramlist={Table} ></Usuarios>
}
export default listar