import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Navbar from '../Navbar/navbar';
import ListaClientes from '../ListaCliente/listacliente';
import EditarInstalacao from '../../EditTable/edittable';
import './listtable.css';
import Footer from '../footer';


import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import Paginator from '../Paginator/paginator';
import Aguarde from '../Aguarde/aguarde';

function ListTable(propsTable){
  const [alterar,setAlterar] = useState(false);

  const [titlefields, setTitleFields] = useState([]);
  const [cli, setCli] = useState([]);
  const [arraypage, setArrayPage] = useState([]);
  const [busca, setBusca] = useState('');
  const [texto, setTexto] = useState('');
  const [ConfirmaExclusao, setConfirmaExclusao] = useState(false);
  const [ConfirmaAlteracao, setConfirmaAlteracao] = useState(false);
  const [confirmacaoId, setconfirmacaoId] = useState('');
  const [nClickPage, setnClickPage] = useState(1); 
  const [FieldSearch, setFieldSearch] = useState({"index":1,"title":""}); 
  const [ShowAguarde, setShowAguarde] = useState(false); 
  
  
  
  
 
  useEffect(function(){
       PageIndex(1);
    }, [busca]);
    function PageIndex(id,indexsearch)
    {
      let chave = '';
      setShowAguarde(true);
      setBusca('');
      setnClickPage(parseInt(id));
      let Filter = "WHERE "+propsTable.paramlist.where;
      let indexColunms = propsTable.paramlist.index;
      if(indexsearch!=undefined)
        indexColunms = indexsearch;   
//        Filter = Filter+" "+propsTable.paramlist.fieldSearch+" like '%"+texto+"%'";
        //Filter = Filter+" "+propsTable.paramlist.fields[FieldSearch.index]+" like '%"+texto+"%'";
        Filter = Filter+" 1=1"+(texto?' AND ' +propsTable.paramlist.fields[FieldSearch.index]+" like '%"+texto+"%'":'');
        let payload = {"data":{
        "table":propsTable.paramlist.table,
        "index":indexColunms,
        "where":Filter,
        "fields":propsTable.paramlist.fields,
        "page_num":parseInt(id),
        "page_size":10
        
        }};
  
        //let fieldstitle = ["Códgio","Nome","E-Mail","Telefone"];
        let fieldstitle = propsTable.paramlist.columns;
      setTitleFields(fieldstitle); 
      axios.post('https://api007.foxspy.com.br/users', payload).then(res => {
          console.log(`statusCode: ${res.status}`);
        setArrayPage(res.data.paginate); 
        console.log(payload);
        setCli(res.data.data.items);
        setShowAguarde(false);
        //console.log(res.data.data.items);
  
      })
      .catch(error => {
        console.error(error)
      }) ;
  
    }
    function deleteRow(id){
      setconfirmacaoId(id);
      setConfirmaExclusao(true);
    }
    function AlterRow(id){
      setShowAguarde(true);
      setconfirmacaoId(id);
      setAlterar(true);
      
      //setconfirmacaoId(id);
      //setConfirmaAlteracao(true);

    }
    function CancelAlter(cancel)

    {
      setConfirmaAlteracao(cancel);
    }
    function AlterReg(id)
    {}
    function deleteReg(id)
    {
      let codigo = id;
      if (isNaN(codigo))
      {
         codigo = "'"+codigo+"'";
      }      
      let payload = {"data":{
        "table":propsTable.paramlist.table,        
        "where":"WHERE "+propsTable.paramlist.fields[0]+" = "+codigo        
        }};
         axios.post('https://api.foxspy.com.br/users/delete', payload).then(res => {
          PageIndex(nClickPage);
      })
      .catch(error => {
        console.error(error)
      }) ;
      setConfirmaExclusao(false);
      
    }
    return <div /*onFocus={()=>setAlterar(false)}*/>
      <Navbar/>
      <div className="container-fluid titulo">
        <h1>{propsTable.paramlist.title}</h1>

        <div className="row">
          <div className="col-4">
          
  <Link to='#' onClick={() => AlterRow(-1)} className="btn btn-primary" type="button"><i className="fas fa-plus"></i> Novo</Link>
          </div>

          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setTexto(e.target.value)} onKeyUp={(e) => (e.key === 'Enter'?setBusca("search"):null)} type="text" className="form-control" placeholder={"Pesquisar"+(FieldSearch.title?" por "+FieldSearch.title:'')} aria-describedby="button-addon2" />
              <button onClick={(e) => setBusca("search")} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
            </div>
          </div>
        </div>        

        <ListaClientes arrayClientes={cli} arrayTitle={titlefields} ClickAlter={AlterRow} ClickDelete={deleteRow} ListRows={PageIndex} setFieldSearch={setFieldSearch}/>
        <Paginator arrayPaginator={arraypage} ClickPage={PageIndex}/>
        
        {ShowAguarde?<Aguarde/>:null}
        {ConfirmaExclusao?<SweetAlert
                        warning
                        showCancel
                        showCloseButton
                        confirmBtnText="Sim"
                        confirmBtnBsStyle="danger"
                        cancelBtnText="Não"
                        cancelBtnBsStyle="light"
                        title="Exclusão"
                        onConfirm={() => deleteReg(confirmacaoId)}
                        onCancel={() => setConfirmaExclusao(false)}          
                        reverseButtons={true}
                      >
                        <b>{propsTable.paramlist.columns[0]+':  '}</b><b>{confirmacaoId}</b><br/><br/>
                        Deseja excluir o registro selecionado?
                      </SweetAlert> : null }
        {alterar?<EditarInstalacao preencher={{"field":"CHAVE","value":(Date.now()+'').substr(0,10)}} ShowAguarde={setShowAguarde} Aguarde={ShowAguarde} Cancelar={setAlterar} Alterar={propsTable.paramalter} ID={confirmacaoId} PageIndex={PageIndex} Pg={nClickPage} />
                       : null }
        {busca!=''?PageIndex(1):null}              
            
        {/*<ListaClientes arrayClientes={clientes} />*/}
      </div>      
      <Footer/>
    </div>
  }


export default ListTable;
