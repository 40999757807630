import React, { useState } from 'react';
import {Link, Redirect}  from 'react-router-dom';
import './edittable.css';
import axios from 'axios';

import SweetAlert from 'react-bootstrap-sweetalert';



function EditTable(props){
    const [editar,setEditar] = useState([]);
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [fone, setFone] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [sucesso, setSucesso] = useState('N');
    const [showform,setShowForm] = useState(true);
    let edicao=[];
    let teste=true;
    buscar();
    function CloseForm()
      {
        setShowForm(false);
        props.Cancelar(false);
      }
    function Alterar(){
      CloseForm();
      //rops.ShowAguarde(true);
      let dados = [];
     
      let payload;
      if (props.ID>0)
      { 
        props.Alterar.fieldsForm.forEach(element => {
          dados.push({"field":element.field,"value":document.getElementById(element.field).value});
        });
        payload= {"data":{
          "table":props.Alterar.table,        
          "where":"WHERE "+props.Alterar.key["field"]+" = "+document.getElementById(props.Alterar.key["field"]).defaultValue,
          "dados":dados        
          }};
        axios.post('https://api007.foxspy.com.br/users/alter', payload).then(res => {
            props.PageIndex(props.Pg);        
          })
        .catch(error => {
          console.error(error);
          setMensagem('Algo deu errado');
        }) ;
      }
      else
      {
        props.Alterar.fieldsForm.forEach(element => {
          if(element.field)
          {
            if((props.Alterar.key["field"]!=element.field) || ((props.Alterar.key["field"]===element.field) && (props.Alterar.key["AUTO_INCREMENT"]!=true))) 
             dados.push({"field":element.field,"value":document.getElementById(element.field).value});
          }
        });
        payload= {"data":{
          "table":props.Alterar.table,        
          "dados":dados        
          }};
        axios.post('https://api007.foxspy.com.br/users/insert', payload).then(res => {
            props.PageIndex(props.Pg);        
          })
        .catch(error => {
          console.error(error);
          setMensagem('Algo deu errado');
        }) ;

      }
      /*if (nome.length === 0){
        setMensagem(document.getElementById('IMEI').innerHTML);
      }*/
    }
    function buscar()
    {
      let fields=[];    
      props.Alterar.fieldsForm.forEach(element => {
          fields.push(element.field);
      });   
      let Filter = "WHERE "+props.Alterar.key["field"]+"="+props.ID;
      let payload = {"data":{
      "table":props.Alterar.table,
      "index":props.Alterar.index,
      "where":Filter,
      "fields":fields,
      "page_num":parseInt(1),
      "page_size":1
        
        }};
                
       axios.post('https://api007.foxspy.com.br/users', payload).then(res => {
            let Dados = res.data.data.items[0];
            if(props.ID>0)
            {
              props.Alterar.fieldsForm.forEach(element => {            
                if(element.type.indexOf('datetime') !== -1)
                {
                  let vDate = Dados[element.field];
                  Dados[element.field]= vDate.replace(' ','T');
                }
              });
            }
            else
            {
              if(props.preencher)
              {
                //lucio retirar
                Dados=[];
                Dados.push({"field":props.preencher["field"],"value":props.preencher["value"]});
                //Dados.push([props.preencher["field"]]props.preencher["value"]);
              }
            }
            //Dados['VENCIMENTO']='2021-06-06T10:10:10';
            setEditar(Dados);
            props.ShowAguarde(false);
           

        //console.log(`statusCode: ${res.status}`);
        //console.log(res.data);
  
      })
      .catch(error => {
        console.error(error)
      }) ;
    }
    return showform&&!props.Aguarde?<SweetAlert
    style={{ width:'95%' }}
    showConfirm={false}
  ><div>
    
          <div className="offset-lg-12 col-lg-12">
    <h1>{props.ID>0?'Alterar':"Novo"} {props.Alterar.title}</h1>
            <form>
              {
                
              props.Alterar.fieldsForm.forEach(element => {
               
                edicao.push(                 
                  <div style={{ width:element.div.row}} class="row">
                    <label style={{ display:"flex" }}className="form-label">{element.title}</label>
                    <div style={{ width:element.div.col }}>
                      <input type={element.type} className="form-control" id={element.field} 
                             defaultValue={props.ID>0?element.type==='datetime-local'?editar[element.field]:editar[element.field]:
                             (props.Alterar.key["field"]===element.field)&&props.Alterar.key["AUTO_INCREMENT"]===true?'99999999':
                             props.preencher["field"]===element.field?props.preencher["value"]:''} 
                             Value={props.ID>0?element.type==='datetime-local'?editar[element.field]:editar[element.field]:
                             (props.Alterar.key["field"]===element.field)&&props.Alterar.key["AUTO_INCREMENT"]===true?'99999999':
                             props.preencher["field"]===element.field?props.preencher["value"]:''}
                      />
                    </div>
                  </div>                  
                );
                /*edicao.push(<div className="mb-3 col-lg-12">                
                <label style={{ display:"flex" }}className="form-label">{element.title}</label>
                <input style={{ width:element.width }} type={element.type} className="form-control" id={element.field} />
                </div>)*/
                
              })}
              

              <div class='row'>
              {edicao}
              </div>

              <div className="text-center">
              <button onClick={CloseForm} type="button" className="btn btn-primary btn-acao">Cancelar</button>
              <button onClick={Alterar} type="button" className="btn btn-primary btn-acao">Salvar</button>
              </div>

              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Redirect to='/app/home' /> : null}

            </form>
          </div>
        </div></SweetAlert>:null
    ;  
  }

export default EditTable;
