import Representantes from '../Components/ListTable/listtable';
import table from '../Components/ListTable/querylist';

function listar()
{
    let Table = new table("revenda");
//    Table.fields="ID,IMEI,MENSAGEM";
    Table.fields=["CODIGO","EMAIL","SENHA","REPRESENTANTE","SALDO","SITE","EMAILCONTATO"];
    Table.columns=["CODIGO","EMAIL","SENHA","REPRESENTANTE","SALDO","SITE","EMAILCONTATO"];
    Table.where="";
    Table.index="CODIGO desc";
    Table.title="Representantes";
    Table.fieldSearch = "CODIGO";
// Form Alterar
    Table.fieldsForm=[
    {"field":"CODIGO","title":"CODIGO","type":"text","div":{"row":'1000px',"col":"180px"}},
    {"field":"EMAIL","title":"EMAIL","type":"email","div":{"row":'550px',"col":"100%"}},
    {"field":"SENHA","title":"SENHA","type":"text","div":{"row":'280px',"col":"100%"}},
    {"field":"SALDO","title":"SALDO","type":"number","div":{"row":'100%',"col":"100%"}},
    {"field":"SITE","title":"SITE","type":"text","div":{"row":'100%',"col":"100%"}},
    {"field":"EMAILCONTATO","title":"EMAILCONTATO","type":"email","div":{"row":'100%',"col":"100%"}},
    ];
    Table.key={"field":"CODIGO","AUTO_INCREMENT":true};
////    

    

return <Representantes paramlist={Table} paramalter={Table} > 
</Representantes>
}
//{alterar?<EditarInstalacao Alterar={propsTable.paramalter}/>: null }
export default listar
