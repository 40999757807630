import SweetAlert from 'react-bootstrap-sweetalert';

function Aguarde(){
    return <SweetAlert
     
    showConfirm={false}
                  ><h1>Aguarde...</h1>
                    <img src="http://i.stack.imgur.com/h6viz.gif"></img>                    
                  </SweetAlert>
                  ;
                  
  }

export default Aguarde;